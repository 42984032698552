<template>
  <v-container>
    <v-row v-if="model">
      <v-col cols="12" xl="5">
        <ValidationObserver tag="div" class="mt-3" ref="form" v-slot="{ invalid }">
          <v-card>
            <slot name="switcher">
              <v-card-title>WhatsApp Business</v-card-title>
            </slot>
            <v-card-text>
              <Objectid v-if="!isCreate" :value="id" />
              <ValidationProvider v-slot="{ errors }" name="clientId" rules="required">
                <v-text-field
                  label="Client ID"
                  v-model="model.clientId"
                  :error="!!errors.length"
                  :error-messages="errors"
                />
              </ValidationProvider>
              <ValidationProvider v-slot="{ errors }" name="clientSecret" rules="required">
                <v-text-field
                  label="Client Secret"
                  v-model="model.clientSecret"
                  :error="!!errors.length"
                  :error-messages="errors"
                />
              </ValidationProvider>
              <ValidationProvider v-slot="{ errors }" name="accessToken" rules="required">
                <v-text-field
                  label="Access Token"
                  v-model="model.accessToken"
                  :error="!!errors.length"
                  :error-messages="errors"
                />
              </ValidationProvider>
              <ValidationProvider v-slot="{ errors }" name="phoneNumberId" rules="required">
                <v-text-field
                  label="Phone Number ID"
                  v-model="model.phoneNumberId"
                  :error="!!errors.length"
                  :error-messages="errors"
                />
              </ValidationProvider>
              <ValidationProvider v-slot="{ errors }" name="waba_id" rules="required">
                <v-text-field
                  label="Whatsapp Business Account ID"
                  v-model="model.waba_id"
                  :error="!!errors.length"
                  :error-messages="errors"
                ></v-text-field>
              </ValidationProvider>
              <ValidationProvider v-slot="{ errors }" name="team">
                <v-autocomplete
                  label="Auto assign sender to the following team:"
                  v-model="model.willAssignSenderToTeam"
                  :error="!!errors.length"
                  :error-messages="errors"
                  :messages="[
                    'The selected team will only be auto-assigned when a new / existing sender account re-login to the instance.',
                  ]"
                  :readonly="!isAdmin"
                  :items="teamAutocomplete"
                  no-filter
                  :item-text="'label'"
                  item-value="_id"
                  :search-input.sync="teamSearch"
                  :loading="teamSearchLoading"
                />
              </ValidationProvider>
            </v-card-text>
            <v-card-actions style="flex-wrap: wrap">
              <v-btn
                v-if="isCreate"
                @click="promptCreate()"
                :disabled="invalid"
                key="create"
                color="primary"
              >
                Save
              </v-btn>
              <v-btn
                v-if="!isCreate"
                @click="promptUpdate()"
                :disabled="invalid"
                key="update"
                color="primary"
              >
                Save
              </v-btn>
              <div class="grow"></div>
              <v-btn v-if="!isCreate" color="error" @click="promptDelete()" key="delete">
                Delete
              </v-btn>
              <custom-dialog ref="confirm" />
            </v-card-actions>
          </v-card>
        </ValidationObserver>
      </v-col>
      <v-col cols="12" xl="3" v-if="!isCreate && senderIdentity">
        <v-card class="mt-3">
          <v-card-title> Sender Identity </v-card-title>
          <v-list three-line>
            <v-list-item
              v-if="senderIdentity"
              :to="{ name: 'Profile', params: { id: senderIdentity._id } }"
            >
              <v-list-item-avatar>
                <v-img :src="avatar"></v-img>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title>
                  <div>{{ [senderIdentity.name_last, senderIdentity.name_first].join(' ') }}</div>
                </v-list-item-title>
                <v-list-item-subtitle>
                  {{ senderIdentity.name }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import api from '@/api';
import {
  chatApiLogout,
  chatApiRefreshQr,
  createGateway,
  deleteGateway,
  IChatapiGateway,
  patchGateway,
  triggerResync,
} from '@/api/gw';
import { getMyTeam, listTeam, readTeam } from '@/api/team';
import Objectid from '@/components/objectid';
import editDialogMixin from '@/services/editDialogMixin';
import { attnThumbHref, getId } from '@/services/filters';
import { omit, pick } from 'lodash';
import { mapActions, mapGetters } from 'vuex';
import CustomDialog from '../../components/CustomDialog';

export default {
  name: 'WabizEdit',
  components: { Objectid, CustomDialog },
  props: {
    data: { type: IChatapiGateway, required: true },
  },
  mixins: [{ ...editDialogMixin, props: undefined }],
  data() {
    return {
      myTeam: null,
      model: {
        apiUrl: null,
        token: null,
        willAssignSenderToTeam: null,
        clientId: null,
        clientSecret: null,
      },
      teamAutocomplete: [],
      teamSearch: null,
      teamSearchLoading: false,
    };
  },
  mounted() {
    this.reload();
  },
  watch: {
    async teamSearch(search) {
      if (this.isAdmin) {
        this.teamAutocomplete = (await listTeam(api, { search })).docs;
      } else {
        this.teamAutocomplete = [await getMyTeam(api)];
      }
    },
    async 'model.willAssignSenderToTeam'(v) {
      if (v && this.isAdmin) {
        this.teamAutocomplete.unshift(await readTeam(api, v));
      } else {
        this.teamAutocomplete = [await getMyTeam(api)];
      }
    },
  },
  computed: {
    ...mapGetters('auth', ['isAdmin', 'isManager']),
    valueProxy() {
      return true;
    },
    id() {
      return getId(this.data);
    },
    crudURL() {
      return 'im/admin/gws/{id}';
    },
    isCreate() {
      return !this.id;
    },
    status() {
      return this.data?.status?.accountStatus;
    },
    substatus() {
      return this.data?.status?.statusData?.substatus;
    },
    isAuthenticated() {
      return this.status === 'authenticated';
    },
    msg() {
      return [this.data?.status?.statusData?.title, this.data?.status?.statusData?.msg]
        .join('\n')
        .trim();
    },
    qrCode() {
      return this.data?.status?.qrCode;
    },
    style() {
      if (this.data.hasWarning) {
        return { color: 'white', 'font-weight': 'bold', 'background-color': 'red' };
      } else {
        if (this.status === 'authenticated') {
          return { color: 'green' };
        } else {
          return { color: 'white', 'font-weight': 'bold', 'background-color': 'rgb(193,155,78)' };
        }
      }
    },
    senderIdentity() {
      return this.data.senderIdentity;
    },
    avatar() {
      return this.senderIdentity?.avatar && attnThumbHref(this.senderIdentity.avatar);
    },
  },
  methods: {
    ...mapActions('alert', ['updateAlertMessage']),
    async reload() {
      this.myTeam = await getMyTeam(api, this.id);
      if (getId(this.model) !== this.id) {
        this.model = new IChatapiGateway(Object.assign({}, this.data));
      } else {
        Object.assign(this.model, omit(this.data, ['apiUrl', 'token', 'willAssignSenderToTeam']));
      }
    },
    handleSuccess(msg = 'Success') {
      return this.$store.dispatch('alert/updateAlertMessage', {
        msg: msg,
        type: 'success',
        color: 'success',
      });
    },
    async promptCreate() {
      try {
        const x = await createGateway(api, this.model);
        this.$emit('create', x.data);
        return x.data;
      } catch (err) {
        this.handleError(err);
      }
    },
    async doUpdate(patch, id) {
      return patchGateway(
        api,
        id,
        pick(patch, [
          'apiUrl',
          'token',
          'willAssignSenderToTeam',
          'clientId',
          'clientSecret',
          'accessToken',
          'phoneNumberId',
          'waba_id',
        ]),
      );
    },
    async doDelete(id) {
      const confirm = await this.$refs.confirm.open(
        'Confirm',
        'Are you sure to delete this Sender?',
        {
          color: 'danger',
          width: '30%',
        },
      );
      if (confirm) {
        const x = await deleteGateway(api, id);
        this.$emit('delete', this.model);
        return x;
      }
    },
    async triggerResync() {
      try {
        await triggerResync(api, this.id);
        this.handleSuccess();
      } catch (err) {
        this.handleError(err);
      }
    },
    async refreshQr() {
      try {
        await chatApiRefreshQr(api, this.id);
        this.handleSuccess();
      } catch (err) {
        this.handleError(err);
      }
    },
    async promptLogout() {
      try {
        if (confirm('Are you sure you want to disconnect the WhatsApp instance?')) {
          await chatApiLogout(api, this.id);
          this.handleSuccess();
        }
      } catch (err) {
        this.handleError(err);
      }
    },
  },
};
</script>

<style lang="scss">
a {
  text-decoration: none;
}
</style>
