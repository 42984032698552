<template>
  <v-container>
    <v-container class="mtb-3">
      <h2 class="mainTitle">System Config</h2>
    </v-container>
    <v-card outlined class="addCardBorderColor">
      <SectionChatGateways />
    </v-card>
  </v-container>
</template>

<script>
import SectionChatGateways from '@/components/SystemConfig/SectionChatGateways';

export default {
  name: 'SystemConfig',
  components: {
    SectionChatGateways,
  },
};
</script>

<style lang="scss" scoped>
.addCardBorderColor {
  border: 5px solid var(--v-borderColor-base);
  border-radius: 20px !important;
}
</style>
