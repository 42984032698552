<template>
  <div></div>
</template>

<script>
import { ILineGateway } from '@/api/gw';

export default {
  name: 'LineListTile',
  props: {
    data: { type: ILineGateway, required: true },
  },
};
</script>
