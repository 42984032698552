<template>
  <v-container class="pa-3" v-if="teamModel">
    <v-dialog v-model="identityDialog">
      <identityDetails :id="thisIdentity"></identityDetails>
    </v-dialog>
    <v-tabs v-model="tab" color="mainTitleLabel" class="mb-3">
      <v-tab>Team Details</v-tab>
      <v-tab>Team Member List</v-tab>
      <v-spacer />
    </v-tabs>
    <custom-dialog ref="confirm">
      <template v-slot:textSlot v-if="showMemberDetail">
        <v-card v-if="showMemberDetailCard">
          <v-card-text>
            <div>{{ model.name }}</div>
            <div>{{ model.role }}</div>
            <div v-if="model.team">{{ model.team.label }}</div>
            <div>{{ model.sid }}</div>
            <div>{{ model.phone }}</div>
            <div>{{ model.email }}</div>
          </v-card-text>
        </v-card>
        <div v-else class="pt-4">
          <span class="itemFieldName">
            {{ chosenMember.role | capitalize }} {{ chosenMember.name }}</span
          >
          <span>
            will be removed from team, all related chats will be cleared from the profile, confirm
            to remove?</span
          >
        </div>
      </template>
    </custom-dialog>
    <v-tabs-items v-model="tab">
      <v-tab-item>
        <v-row style="padding-bottom: 10px">
          <v-col cols="4">
            <custom-label item-field-name="Status" />
            <v-btn
              min-width="110"
              min-height="56"
              large
              :class="!teamModel.blocked ? 'primary--text v-item--active v-btn--active' : ''"
              :value="false"
              @click="blocked('active')"
              >Active
            </v-btn>
            <v-btn
              large
              min-width="110"
              min-height="56"
              :value="true"
              :class="teamModel.blocked ? 'primary--text v-item--active v-btn--active' : ''"
              @click="blocked('blocked')"
              >Blocked
            </v-btn>
          </v-col>
          <v-col cols="4">
            <custom-label item-field-name="Need Approval" />
            <div>
              <v-btn
                min-width="110"
                min-height="56"
                large
                :class="teamModel.needApproval ? 'primary--text v-item--active v-btn--active' : ''"
                :value="false"
                @click="blocked('yes')"
                >Yes
              </v-btn>
              <v-btn
                large
                min-width="110"
                min-height="56"
                :value="true"
                :class="!teamModel.needApproval ? 'primary--text v-item--active v-btn--active' : ''"
                @click="blocked('no')"
                >No
              </v-btn>
            </div>
          </v-col>
        </v-row>
        <v-card style="margin: 3px" elevation="3">
          <v-card-title>
            <v-spacer />
            <v-btn color="success" class="text-right" @click="saveDialog" :disabled="!valid"
              >Save
            </v-btn>
          </v-card-title>
          <v-card-text>
            <v-row>
              <v-col>
                <v-form class="teamForm" v-model="valid" lazy-validation>
                  <!-- <v-row class="leftInfo">
                    <v-col class="pl-0" cols="6">
                      <label-with-text-field
                        class="inputInfo"
                        item-field-name="Team Label"
                        v-model="teamModel.label"
                        :hide-details="valid"
                      />
                    </v-col>
                    <v-col class="pl-0" cols="6">
                      <div class="align-center workingPeriod">
                        <span class="itemFieldName mb-3" style="white-space: nowrap"
                          >Working Period</span
                        >
                        <v-autocomplete
                          label="Timezone"
                          solo
                          class="mt-3"
                          v-model="timezone"
                          :items="timezones"
                          :hide-details="valid"
                        />
                        <v-spacer /></div
                    ></v-col>
                  </v-row> -->
                  <div class="d-flex flex-column leftInfo">
                    <label-with-text-field
                      class="inputInfo"
                      item-field-name="Team Label"
                      v-model="teamModel.label"
                      :hide-details="valid"
                    />
                    <div class="align-center workingPeriod">
                      <span class="itemFieldName mb-3" style="white-space: nowrap"
                        >Working Period</span
                      >
                      <v-autocomplete
                        label="Timezone"
                        solo
                        class="mt-3"
                        v-model="timezone"
                        :items="timezones"
                        :hide-details="valid"
                      />
                      <v-spacer />
                    </div>
                  </div>

                  <v-row class="mb-8 rightInfo" style="margin-left: -11px">
                    <v-col class="pa-0" cols="12">
                      <v-card elevation="2" min-height="288">
                        <v-card-text>
                          <label-with-text-field
                            item-field-name="Activity Labels"
                            v-model="teamModel.activityLabels"
                            :tag="ChipsCombobox"
                          />
                          <label-with-text-field
                            item-field-name="Client Tags"
                            v-model="teamModel.clientTags"
                            :tag="ChipsCombobox"
                          />
                          <p>ChatGPT System Content</p>
                          <v-textarea
                            v-model="teamModel.ChatGPTSystemContent"
                            :hide-details="valid"
                          />
                          <br />
                          <p>ChatGPT Chat Config</p>
                          <p>
                            Temperature (temperature)
                            <v-tooltip top>
                              <template v-slot:activator="{ on, attrs }">
                                <v-icon color="primary" dark v-bind="attrs" v-on="on">
                                  mdi-alert-circle-outline
                                </v-icon>
                              </template>
                              <span style="display: block">
                                What sampling temperature to use, between 0 and 2. Higher values
                                like 0.8 will make the output more random, while lower values like
                                0.2 will make it more focused and deterministic.
                              </span>
                            </v-tooltip>
                          </p>
                          <br />
                          <v-slider
                            v-model="computedChatTemperature"
                            step="0.1"
                            min="0"
                            max="2"
                            thumb-label="always"
                          ></v-slider>
                          <p>
                            Max Tokens (max_tokens)
                            <v-tooltip top>
                              <template v-slot:activator="{ on, attrs }">
                                <v-icon color="primary" dark v-bind="attrs" v-on="on">
                                  mdi-alert-circle-outline
                                </v-icon>
                              </template>
                              <span style="display: block">
                                The maximum number of tokens to generate in the chat completion. The
                                total length of input tokens and generated tokens is limited by the
                                model's context length.
                              </span>
                            </v-tooltip>
                          </p>
                          <br />
                          <v-text-field type="number" v-model="computedChatMaxTokens">
                          </v-text-field>
                        </v-card-text>
                      </v-card>
                    </v-col>
                  </v-row>
                </v-form>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="text-end">
                <v-dialog v-model="dialog" max-width="40%">
                  <v-card>
                    <v-card-title class="text-h5"> Reminder</v-card-title>
                    <v-card-text style="font-size: 16px" v-if="teamData.length">
                      <span v-for="x in teamData" :key="x._id">
                        {{ x.role | capitalize }}
                        {{ x.name }},
                      </span>
                      currently not in your team, confirm to change team?
                    </v-card-text>
                    <v-card-text v-else> Are you sure?</v-card-text>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn color="primary" text @click="dialog = false">Cancel</v-btn>
                      <v-btn color="danger" text @click="actionSave">Yes</v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-tab-item>
      <v-tab-item>
        <v-card style="margin: 3px">
          <v-card-text>
            <custom-label item-field-name="Select Team Member" />
            <v-autocomplete
              ref="input"
              outlined
              v-model="model"
              :items="items"
              :search-input.sync="autoSearch"
              item-text="name"
              label="Select one"
              return-object
              @keyup.enter="insertDialog(model)"
              :item-value="x => x"
            >
              <template v-slot:item="{ item }">
                <v-list-item-content @click="insertDialog(item)">
                  <v-list-item-title v-text="item.name" />
                  <v-list-item-subtitle v-text="item.role" />
                  <v-list-item-subtitle v-text="item.sid" />
                  <v-list-item-subtitle v-if="item.team" v-text="item.team.label" />
                </v-list-item-content>
              </template>
            </v-autocomplete>
            <custom-label item-field-name="Team List" />
            <v-row style="margin: -10px -10px">
              <v-col cols="4" v-for="x in teamList" :key="x._id">
                <Card :content="x" class="clickable" @click.native="actionDetails(x)">
                  <template v-slot:deleteCard>
                    <v-btn
                      text
                      small
                      color="danger"
                      style="color: white"
                      @click.stop="deleteCardDialog(x, teamList)"
                    >
                      Remove
                    </v-btn>
                  </template>
                </Card>
              </v-col>
              <v-col cols="4" :key="x._id" v-for="x in teamData">
                <Card :content="x">
                  <template v-slot:deleteCard>
                    <v-btn
                      text
                      x-small
                      color="danger"
                      style="color: white"
                      @click.stop="deleteCardDialog(x, teamList)"
                    >
                      Remove
                    </v-btn>
                  </template>
                </Card>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-tab-item>
    </v-tabs-items>
  </v-container>

  <div v-else class="placeholder" style="min-height: 80vh"></div>
</template>

<script>
import api from '@/api';
import { crudList, crudUpdate } from '@/api/crud';
import { readTeam } from '@/api/team';
import CustomDialog from '@/components/CustomDialog';
import ChipsCombobox from '@/components/customFormLabel/ChipsCombobox';
import CustomLabel from '@/components/customFormLabel/customLabel';
import LabelWithTextField from '@/components/customFormLabel/labelWithTextField';
import Card from '@/components/Identity/IdentityCard';
import listViewPageMixin from '@/services/listViewPageMixin';
import identityDetails from '@/views/Identities/identityDetails';
import { omit, pick, get } from 'lodash';
import { mapActions } from 'vuex';
import { capitalize } from '@/services/filters';
import timezones from '@/services/timezones.json';

export default {
  name: 'TeamDetails',
  mixins: [listViewPageMixin],
  components: {
    CustomDialog,
    LabelWithTextField,
    CustomLabel,
    Card,
    identityDetails,
  },
  props: {
    id: {},
  },

  data() {
    return {
      select: [],
      items: [],
      model: [],
      tab: null,
      dialog: false,
      identityDialog: false,
      teamData: [],
      teamList: {},
      teamListId: [],
      showMemberDetail: false,
      showMemberDetailCard: false,
      chosenMember: {},
      removeTeam: [],
      autoSearch: null,
      teamModel: null,
      thisIdentity: null,
      valid: true,
    };
  },
  methods: {
    ...mapActions('alert', ['updateAlertMessage']),
    async reload() {
      this.teamModel = await readTeam(api, this.id);
      await this.showTeamList();
    },
    async insert(item) {
      if (!this.teamData.find(x => x._id === item._id)) {
        this.teamList.push(item);
      }
      this.items = [];
      this.$refs.input.blur();
    },
    async actionDetails(x) {
      this.thisIdentity = x._id;
      this.identityDialog = true;
    },
    async actionSave(type) {
      try {
        let payload;
        if (type === 'status') {
          payload = pick(this.teamModel, ['blocked']);
        } else if (type === 'approve') {
          payload = pick(this.teamModel, ['needApproval']);
        } else {
          payload = omit(this.teamModel, ['blocked', 'needApproval']);
        }
        await crudUpdate(api, 'im/admin/teams', this.id, payload);
        await this.$store.dispatch('alert/updateAlertMessage', {
          msg: 'Save Success',
          type: 'success',
          color: 'success',
        });
      } catch (err) {
        let msg = err.message;
        if (err.code === 11000) {
          if ('label' in err.keyValue) {
            msg = 'Duplicate Team Label';
          }
        }
        await this.$store.dispatch('alert/updateAlertMessage', {
          msg,
        });
      }
      this.dialog = false;
    },
    actionDelete(i, team) {
      this.removeTeam.push(team[i]);
    },
    async insertDialog(item) {
      this.showMemberDetail = true;
      this.showMemberDetailCard = true;
      let name = item.name;
      let team = this.teamModel?.label;
      name = capitalize(name);
      const insert = await this.$refs.confirm.open(
        'Select Team Member',
        'Are you sure to select ' + name + ' to ' + team + '?',
        {
          color: 'danger',
          width: '30%',
        },
      );
      if (insert) {
        await crudUpdate(api, 'im/admin/profiles', item.id, { team: this.id });
        await this.insert(item);
        await this.$store.dispatch('alert/updateAlertMessage', {
          msg: capitalize(item.role) + ' ' + item.name + ' successfully added',
          type: 'success',
          color: 'success',
        });
      }
      this.showMemberDetail = false;
      this.showMemberDetailCard = false;
    },
    async deleteCardDialog(i, team) {
      this.chosenMember = i;
      this.showMemberDetail = true;
      let role = i.role;
      let msg = '';
      switch (role) {
        case 'manager': {
          msg =
            'Manager ' +
            i.name +
            ' will be removed from team, all related chats will be cleared from the profile, confirm to remove?';
          break;
        }
        case 'agent': {
          msg =
            'Agent ' +
            i.name +
            ' will be removed from team, all related chats will be cleared from the profile, confirm to remove?';
        }
      }
      const remove = await this.$refs.confirm.open('Remove', '', {
        color: 'danger',
        width: '30%',
      });
      if (remove) {
        try {
          this.deleteCard(i._id, team);
          await crudUpdate(api, 'im/admin/profiles', i._id, { team: null });
          await this.$store.dispatch('alert/updateAlertMessage', {
            msg: capitalize(i.role) + ' ' + i.name + ' successfully deleted',
            type: 'success',
            color: 'success',
          });
        } catch (err) {
          await this.$store.dispatch('alert/updateAlertMessage', {
            msg: err,
          });
        }
      }
      this.showMemberDetail = false;
    },
    deleteCard(i, team) {
      const indexOf = team.findIndex(i => i === team._id);
      this.actionDelete(indexOf, team);
      team.splice(indexOf, 1);
      this.teamListId = [];
      this.teamListId = [...this.teamList];
    },

    async showTeamList() {
      let res = await crudList(api, 'im/admin/profiles', {
        filter: { role: ['manager', 'agent'], team: this.id },
        limit: 99,
        populate: 'team',
      });
      this.teamList = res.docs;
    },
    async blocked(a) {
      const msg = {
        blocked: 'The team will be blocked from login and working. Confirm to block?',
        active: 'The team will be active for login and working. Confirm to activate?',
        yes: 'The team can NOT log in without manager approval. Confirm to switch setting?',
        no: 'The team can log in without manager approval. Confirm to switch setting?',
      }[a];
      const title = {
        blocked: 'Status',
        active: 'Status',
        yes: 'Approval',
        no: 'Approval',
      }[a];
      const confirm = await this.$refs.confirm.open(title, msg, {
        color: 'danger',
        width: '30%',
      });
      if (confirm) {
        if (a === 'blocked' || a === 'active') {
          this.teamModel.blocked = a === 'blocked';
          await this.actionSave('status');
        } else if (a === 'yes' || a === 'no') {
          this.teamModel.needApproval = a === 'yes';
          await this.actionSave('approve');
        }
      }
    },
    async saveDialog() {
      const save = await this.$refs.confirm.open('Save', 'Confirm to save?', {
        color: 'danger',
        width: '30%',
      });
      if (save) {
        await this.actionSave();
      }
    },
  },
  computed: {
    ChipsCombobox: () => ChipsCombobox,
    timezones() {
      return timezones.map(x => ({
        text: x.text, // `(${x.offset >= 0 ? '+' + x.offset : x.offset}) ${x.value}`,
        value: x.offset + (x.isdst ? -1 : 0),
      }));
    },
    timezone: {
      set(v) {
        this.teamModel.workTimePeriod.timezone = v * 60;
      },
      get() {
        return this.teamModel.workTimePeriod.timezone / 60;
      },
    },
    computedChatTemperature: {
      set(v) {
        if (!this.teamModel.ChatGPTChatConfig) this.teamModel.ChatGPTChatConfig = {};
        this.teamModel.ChatGPTChatConfig.temperature = v;
      },
      get() {
        return get(this.teamModel, 'ChatGPTChatConfig.temperature', 1);
      },
    },
    computedChatMaxTokens: {
      set(v) {
        if (!this.teamModel.ChatGPTChatConfig) this.teamModel.ChatGPTChatConfig = {};
        this.teamModel.ChatGPTChatConfig.max_tokens = v;
      },
      get() {
        return get(this.teamModel, 'ChatGPTChatConfig.max_tokens', 250);
      },
    },
  },
  watch: {
    async autoSearch(val) {
      let res = await crudList(api, 'im/admin/profiles', {
        search: val,
        filter: { role: ['agent', 'manager'] },
        populate: 'team',
        limit: 99,
      });
      this.items = res.docs;
      for (let i = 0; i < this.teamList.length; i++) {
        this.teamListId.push(this.teamList[i]._id);
      }
      this.items = this.items.filter(x => !this.teamListId.includes(x.id));
    },
  },
};
</script>
<style scope>
.v-application .primary--text {
  background-color: 'white' !important;
  color: var(--v-lightblue-base) !important;
  caret-color: var(--v-lightblue-base) !important;
}

.v-application .success {
  background-color: var(--v-lightblue-base) !important;
  border-color: var(--v-lightblue-base) !important;
}

.teamForm {
  display: flex;
  background-color: var(--v-twoColumnBackgroundColor-base);
  border: 4px solid var(--v-borderColor-base);
  border-radius: 20px;
}

.leftInfo {
  width: 45%;
  margin: 25px;
}

.leftInfo > * {
  margin-bottom: 10px;
}

#app .rightInfo {
  padding-top: 0px !important;
  width: 45%;
  margin: 25px;
  margin-left: 0px !important;
}

.workingPeriod {
  padding: 0px;
  padding-left: 30px;
  background-color: white;
}

.teamForm .v-input__slot {
  //width: 200px !important; min-height: 40px !important;
  padding-bottom: 0 !important;
}

.teamForm .workingPeriod .v-input__slot {
  width: 200px !important;
}
</style>
