<template>
  <div></div>
</template>

<script>
import { IWabizGateway } from '@/api/gw';

export default {
  name: 'WabizListTile',
  props: {
    data: { type: IWabizGateway, required: true },
  },
};
</script>
