<template>
  <div>
    <v-card-title>
      <v-btn v-if="!!selected" icon @click="selected = selectedIndex = null" class="goBack">
        <v-icon>mdi-arrow-left</v-icon>
      </v-btn>
      Chat Gateways
    </v-card-title>
    <v-row no-gutters>
      <v-col cols="12" md="6" lg="4" xl="3">
        <v-list three-line class="list" :class="{ selected: !!selected }">
          <v-menu absolute offset-y close-on-content-click close-on-click v-if="isAdmin">
            <template v-slot:activator="{ on, attrs }">
              <v-list-item
                v-on="on"
                v-bind="attrs"
                :inputValue="selected && selected._id === undefined"
                class="addParent"
              >
                <span class="text--secondary">Add...</span>
              </v-list-item>
            </template>
            <v-list>
              <v-list-item v-for="x in availablePlatforms" :key="x.value" @click="create(x.value)">
                <v-list-item-title>{{ x.text }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
          <v-list-item v-if="!docs.length">
            <v-list-item-content>
              <v-list-item-title>
                <i>- No items -</i>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item
            v-for="x in docs"
            @click="selected = x"
            :key="x._id"
            :inputValue="selected === x"
          >
            <v-list-item-content>
              <v-list-item-title>
                <div>
                  Platform:
                  <strong style="text-transform: capitalize">{{
                    displayPlatformName(x.platform)
                  }}</strong>
                </div>
                <div v-if="x.senderIdentity">
                  Sender: <strong>{{ x.senderIdentity | profileDisplayName(true) }}</strong>
                </div>
              </v-list-item-title>
              <v-list-item-subtitle>
                <component :is="x.platform + 'ListTile'" :data="x" />
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-col>
      <v-col cols="12" md="6" lg="8" xl="9">
        <component
          v-if="selected"
          :is="selected.platform + 'Edit'"
          :data="selected"
          style="flex: 1"
          @update="reload"
          @create="
            x => {
              selected = x;
              reload();
            }
          "
          @delete="reload"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import api from '@/api';
import { IChatGateway, listGateways } from '@/api/gw';
import { populatedCtor } from '@/api/mongooseTypes';
import { createSocket } from '@/api/socket';
import ChatapiEdit from '@/components/SystemConfig/ChatapiEdit';
import ChatapiListTile from '@/components/SystemConfig/ChatapiListTile';
import TelegramEdit from '@/components/SystemConfig/TelegramEdit';
import TelegramListTile from '@/components/SystemConfig/TelegramListTile';
import LineListTile from '@/components/SystemConfig/LineListTile';
import LineEdit from '@/components/SystemConfig/LineEdit';
import { oidEquals } from '@/services/utils';
import { Socket } from 'socket.io-client';
import Vue from 'vue';
import { VListItem } from 'vuetify/lib/components';
import { mapGetters } from 'vuex';
import WabizEdit from '@/components/SystemConfig/WabizEdit';
import WabizListTile from '@/components/SystemConfig/WabizListTile';

const CustomVaSidebarLink1 = Vue.component('CustomVaSidebarLink1', {
  props: {
    title: String,
    isActive: Boolean,
  },
  components: {
    VListItem,
  },
  render() {
    const { title, isActive } = this;
    return (
      <v-list-item value={isActive}>
        <slot>
          <p>{title}</p>
        </slot>
      </v-list-item>
    );
  },
});

export default {
  name: 'SectionChatGateways',
  components: {
    ChatapiEdit,
    ChatapiListTile,
    LineEdit,
    LineListTile,
    TelegramEdit,
    TelegramListTile,
    WabizEdit,
    WabizListTile,
    CustomVaSidebarLink1,
  },
  data() {
    return {
      docs: [],
      selected: null,
      refreshInterval: null,
      /** @type {Socket} */
      io: null,
    };
  },
  mounted() {
    // setInterval to force reload
    this.refreshInterval = setInterval(this.reload, 60000);
    this.reload();
    // [check] nsp:/gw realtime update gw status
    this.io = createSocket(api, '/gw');
    this.io.on('update', gw => {
      gw = populatedCtor(gw, IChatGateway);
      if (this.isAdmin || oidEquals(this.myTeam, gw.willAssignSenderToTeam)) {
        this.onUpdateGw(gw);
      }
    });
  },
  beforeDestroy() {
    clearInterval(this.refreshInterval);
    this.io?.disconnect();
  },
  computed: {
    ...mapGetters(['debug']),
    ...mapGetters('auth', ['isAdmin', 'myTeam']),
    availablePlatforms() {
      return [
        this.debug && { text: 'WhatsApp', value: 'chatapi' },
        { text: 'Telegram', value: 'telegram' },
        { text: 'Line', value: 'line' },
        { text: 'WhatsApp Business', value: 'wabiz' },
      ].filter(x => !!x.value === true);
    },
  },
  methods: {
    create(x) {
      this.selected = { _id: undefined, platform: x };
    },
    async reload() {
      try {
        const docs = (
          await listGateways(api, {
            limit: 300,
            populate: JSON.stringify({
              path: 'senderIdentity',
            }),
          })
        ).docs;
        const selectedId = this.selected?._id;
        if (selectedId) {
          this.selected = docs.find(x => x._id === selectedId);
        }
        this.docs = docs;
      } catch (e) {
        console.error(e);
      }
    },
    /**
     * @param {IChatGateway} gw
     */
    onUpdateGw(gw) {
      const existing = this.docs.findIndex(x => x._id === gw._id);
      if (~existing) {
        this.$set(this.docs, existing, gw);
        if (gw._id === this.selected?._id) {
          this.selected = gw;
        }
      }
    },
    displayPlatformName(name) {
      return (
        { chatapi: 'WhatsApp', telegram: 'Telegram Bot', wabiz: 'WhatsApp Business' }[name] || name
      );
    },
  },
};
</script>

<style scoped lang="scss">
@import '~vuetify/src/styles/settings/_variables.scss';

.va-sidebar {
  position: relative;
  top: initial;
  left: initial;
  height: auto;
  min-height: initial;

  ::v-deep &__menu {
    padding: 0;
  }

  ::v-deep .va-sidebar-link {
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  }
}

@media #{map-get($display-breakpoints, 'sm-and-down')} {
  .list.selected {
    display: none;
  }
}
@media #{map-get($display-breakpoints, 'md-and-up')} {
  .goBack {
    display: none;
  }
}

.addParent {
  display: flex;
  justify-content: flex-end;
}
.text--secondary {
  background-color: var(--v-drawerHover-base);
  border-color: var(--v-drawerHover-base);
  padding: 10px;
  box-shadow: 3px 3px 3px grey;
  animation: bounce 2s linear 1;
}
@keyframes bounce {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}
.text--secondary:active {
  box-shadow: 1px 1px 1px grey;
  transform: translateY(2px);
}
.list {
  background-color: transparent;
}
</style>
