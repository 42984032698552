var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[(_vm.model)?_c('v-row',[_c('v-col',{attrs:{"cols":"12","xl":"5"}},[_c('ValidationObserver',{ref:"form",staticClass:"mt-3",attrs:{"tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('v-card',[_vm._t("switcher",function(){return [_c('v-card-title',[_vm._v("Line")])]}),_c('v-card-text',[(!_vm.isCreate)?_c('Objectid',{attrs:{"value":_vm.id}}):_vm._e(),_c('ValidationProvider',{attrs:{"name":"clientId","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Client ID","error":!!errors.length,"error-messages":errors},model:{value:(_vm.model.clientId),callback:function ($$v) {_vm.$set(_vm.model, "clientId", $$v)},expression:"model.clientId"}})]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"clientSecret","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Client Secret","error":!!errors.length,"error-messages":errors},model:{value:(_vm.model.clientSecret),callback:function ($$v) {_vm.$set(_vm.model, "clientSecret", $$v)},expression:"model.clientSecret"}})]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"team"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-autocomplete',{attrs:{"label":"Auto assign sender to the following team:","error":!!errors.length,"error-messages":errors,"messages":[
                  'The selected team will only be auto-assigned when a new / existing sender account re-login to the instance.' ],"readonly":!_vm.isAdmin,"items":_vm.teamAutocomplete,"no-filter":"","item-text":'label',"item-value":"_id","search-input":_vm.teamSearch,"loading":_vm.teamSearchLoading},on:{"update:searchInput":function($event){_vm.teamSearch=$event},"update:search-input":function($event){_vm.teamSearch=$event}},model:{value:(_vm.model.willAssignSenderToTeam),callback:function ($$v) {_vm.$set(_vm.model, "willAssignSenderToTeam", $$v)},expression:"model.willAssignSenderToTeam"}})]}}],null,true)})],1),_c('v-card-actions',{staticStyle:{"flex-wrap":"wrap"}},[(_vm.isCreate)?_c('v-btn',{key:"create",attrs:{"disabled":invalid,"color":"primary"},on:{"click":function($event){return _vm.promptCreate()}}},[_vm._v(" Save ")]):_vm._e(),(!_vm.isCreate)?_c('v-btn',{key:"update",attrs:{"disabled":invalid,"color":"primary"},on:{"click":function($event){return _vm.promptUpdate()}}},[_vm._v(" Save ")]):_vm._e(),_c('div',{staticClass:"grow"}),(!_vm.isCreate)?_c('v-btn',{key:"delete",attrs:{"color":"error"},on:{"click":function($event){return _vm.promptDelete()}}},[_vm._v(" Delete ")]):_vm._e(),_c('custom-dialog',{ref:"confirm"})],1)],2)]}}],null,true)})],1),(!_vm.isCreate && _vm.senderIdentity)?_c('v-col',{attrs:{"cols":"12","xl":"3"}},[_c('v-card',{staticClass:"mt-3"},[_c('v-card-title',[_vm._v(" Sender Identity ")]),_c('v-list',{attrs:{"three-line":""}},[(_vm.senderIdentity)?_c('v-list-item',{attrs:{"to":{ name: 'Profile', params: { id: _vm.senderIdentity._id } }}},[_c('v-list-item-avatar',[_c('v-img',{attrs:{"src":_vm.avatar}})],1),_c('v-list-item-content',[_c('v-list-item-title',[_c('div',[_vm._v(_vm._s([_vm.senderIdentity.name_last, _vm.senderIdentity.name_first].join(' ')))])]),_c('v-list-item-subtitle',[_vm._v(" "+_vm._s(_vm.senderIdentity.name)+" ")])],1)],1):_vm._e()],1)],1)],1):_vm._e()],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }